<template>
  <v-row>
    <!-- Primeira coluna -->
    <v-col cols="6">
      <v-card outlined>
        <v-card-title class="d-flex justify-center font-weight-bold">Dados da Evolução</v-card-title>
        <v-card-text>
          <p><strong>Data da Visita / Evolução:</strong> 
            <span v-if="item.dataInicioEvolucao">  {{ item.dataInicioEvolucao }} - De: {{item.hora_inicio}} às {{item.hora_fim}}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Profissional: </strong> 
            <span v-if="item.profissional.apelido"> {{ item.profissional.apelido }} - Especialidade: {{item.especialidade.nome}}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Diagnóstico de {{item.especialidade.nome}}:</strong> 
            <span v-if="item.diagnostico_clinico"> {{ item.diagnostico_clinico }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Quadro Clinico:</strong> 
            <span v-if="item.quadro_clinico"> {{ item.quadro_clinico }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Texto Evolução:</strong> 
            <span v-if="item.texto_evolucao"> {{ item.texto_evolucao }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Texto Meta:</strong> 
            <span v-if="item.texto_meta"> {{ item.texto_meta }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Plano de Cuidados / Conduta :</strong> 
            <span v-if="item.conduta"> {{ item.conduta }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Texto para Prorrogação:</strong> 
            <span v-if="item.texto_info"> {{ item.texto_info }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Segunda coluna -->
    <v-col cols="6">
      <v-card outlined>
        <v-card-title class="d-flex justify-center font-weight-bold">Dados do Paciente</v-card-title>
        <v-card-text>
          <p><strong>Nome:</strong>  {{ paciente.nome }}</p>
          <p><strong>CPF:</strong> 
            <span v-if="paciente.cpf">  {{ formatCPF(paciente.cpf) }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Data de Nascimento:</strong> 
            <span v-if="paciente.data_nascimento">  {{ dataFormatada(paciente.data_nascimento) }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Telefone:</strong> 
            <span v-if="paciente.telefone">  {{ formatarTelefone(paciente.telefone) }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Endereço Completo:</strong> 
            <span v-if="enderecoCompleto">  {{ enderecoCompleto }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Médico:</strong> 
            <span v-if="paciente.medico">  {{ paciente.medico.apelido }}</span>
            <span v-else class="text--red"> Médico não cadastrado</span>
          </p>
          <p><strong>Operadora:</strong> 
            <span v-if="paciente.operadora">  {{ paciente.operadora.nome }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
          <p><strong>Nr Carteirinha:</strong> 
            <span v-if="paciente.nr_carteirinha">  {{ paciente.nr_carteirinha }}</span>
            <span v-else class="text--red"> Não cadastrado</span>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>

  import api from "../../http";
  import UtilsFunc from "../../service/utilsFunc";

  export default {
  name: "EvolucaoExpand",
  data: () =>
     UtilsFunc.withCRUDUtils({
    isLoading: true,
    paciente:[],
  }),
  props: {
    pacienteId: {
      type: Number,
      required: true
    },
    evolucaoId: {
      type: Number,
      required: true
    },
    item: Object,
  },
  methods: {
    async getPaciente() {
        try {
          const { data } = await api.get( `/pacientes/detalhes/?paciente=${this.pacienteId}` );
          this.paciente = data[0]
          // console.log('Paciente ->', this.paciente)
        } catch (error){
          console.log('Aconetceu algum erro ao carregar getPacientes', error)
        }  
    },
    dataFormatada(dateStr) {
      if (!dateStr) {
        return '';  // Retorna uma string vazia ou uma mensagem padrão se a data não estiver definida
      }
      const [year, month, day] = dateStr.split("-");
      return `${day}/${month}/${year}`;
    },
    formatCPF(cpf) {
      if (!cpf) return '';
      // Aplica a máscara de CPF
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formatarTelefone(telefone) {
      if (!telefone) return 'Telefone não cadastrado';
      const ddd = telefone.slice(0, 2);
      const parte1 = telefone.slice(2, 7);
      const parte2 = telefone.slice(7);
      return `(${ddd}) ${parte1} - ${parte2}`;
    },
  },
  computed: {
    enderecoCompleto() {
      const endereco = this.paciente.endereco;
      if (!endereco) return 'Endereço não cadastrado';
      return `${endereco.logradouro}, ${endereco.nr}, ${endereco.bairro}, ${endereco.cidade}, ${endereco.estado}, CEP: ${endereco.cep}`;
    },
    
  },
  mounted() {
    this.getPaciente();

  }
};
</script>

<style scoped>
.text--red {
  color: red;
}

</style>